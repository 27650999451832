<template>
  <base-layout>
  <button type="button" class="btn btn-primary mail-button col-12 col-md-3  m-md-3" v-if="shareData.length >0" @click="exportViaMail(true,true)">
       <font-awesome-icon :icon="['fal', 'envelope']" style="font-size: 1.2rem; margin-right:10px"/>
       {{ wordings[currentLanguage].MAIL_EXPORT }} 
         <font-awesome-icon :icon="['fal', 'envelope']" style="font-size: 1.2rem; margin-left:10px"/>
      </button>
      <IonSegment scrollable style="justify-content: flex-start" :value="activeSection">
        <IonSegmentButton mode="md" value="favorites" style="max-width: none !important"
                          @click="activeSection = 'favorites'">{{ wordings[currentLanguage].FAVS }}
        </IonSegmentButton>
        <IonSegmentButton mode="md" value="notes" style="max-width: none !important"
                          @click="activeSection = 'notes'">{{ wordings[currentLanguage].NOTES }}
        </IonSegmentButton>
      </IonSegment>
    <ion-content class="has-header has-subheader">
      
        <!--IonSegmentButton style="min-width:50px; width: 160px;max-width:160px; " @click="exportViaMail(true,true)">
          <span>{{ wordings[currentLanguage].MAIL_EXPORT }} <font-awesome-icon :icon="['fal', 'envelope']"
                                                                               style="font-size: 1rem; padding-top: 2px"/></span>
        </IonSegmentButton-->
      
      
      <!--    await this.export(true, true)
-->
<div class="loading-indicator" v-if="!loaded">
        <ion-spinner style="color: black"></ion-spinner>
        <div style="color: black !important;font-size:1.2rem">Syncing favorites</div>
      </div>

      <!----acordeon-------------->
      <div class="accordion" id="accordionExampleFav" style="margin-top: 10px" v-if="activeSection == 'favorites'">
        <div class="accordion-item" v-if="sessions.length > 0">
          <h2 class="accordion-header" id="sessionHeadingFav">
            <button class=" position-relative accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSessionsFav" aria-expanded="false" aria-controls="collapseSessionsFav">
              {{ wordings[currentLanguage].SECTION_SESSION }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute top-0 end translate-middle badge rounded-pill bg-primary">
                {{ sessions.length }}
              </span>
            </button>
          </h2>
          <div id="collapseSessionsFav" class="accordion-collapse collapse" aria-labelledby="sessionHeadingFav"
               data-bs-parent="#accordionExampleFav">
            <div class="accordion-body">
              <session-list :sessions="sessions"></session-list>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="presentations.length > 0">
          <h2 class="accordion-header" id="presentationHeadingFav">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapsePresentationsFav" aria-expanded="false"
                    aria-controls="collapsePresentationsFav">
              {{ wordings[currentLanguage].SECTION_PRESENTATION }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute top-0 end translate-middle badge rounded-pill bg-primary">
                {{ presentations.length }}
              </span>
            </button>
          </h2>
          <div id="collapsePresentationsFav" class="accordion-collapse collapse"
               aria-labelledby="presentationHeadingFav"
               data-bs-parent="#accordionExampleFav">
            <div class="accordion-body">
              <presentation-list :presentations="presentations" :with-date="true"></presentation-list>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="persons.length > 0">
          <h2 class="accordion-header" id="personHeadingFav">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapsePersonsFav" aria-expanded="false" aria-controls="collapsePersonsFav">
              {{ wordings[currentLanguage].SECTION_PERSON }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute top-0 end translate-middle badge rounded-pill bg-primary">
                {{ persons.length }}
              </span>
            </button>
          </h2>
          <div id="collapsePersonsFav" class="accordion-collapse collapse" aria-labelledby="personHeadingFav"
               data-bs-parent="#accordionExampleFav">
            <div class="accordion-body">
              <person-list :persons="persons"></person-list>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="exhibitors.length > 0">
          <h2 class="accordion-header" id="exhibitorHeadingFav">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseExhibitorsFav" aria-expanded="false" aria-controls="collapseExhibitorsFav">
              {{ wordings[currentLanguage].EXHIBITORS }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute top-0 end translate-middle badge rounded-pill bg-primary">
                {{ exhibitors.length }}
              </span>
            </button>
          </h2>
          <div id="collapseExhibitorsFav" class="accordion-collapse collapse" aria-labelledby="exhibitorHeadingFav"
               data-bs-parent="#accordionExampleFav">
            <div class="accordion-body">
              <exhibitor-list :exhibitors="exhibitors"></exhibitor-list>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordionExampleNote" style="margin-top: 10px" v-if="activeSection == 'notes'">
        <div class="accordion-item" v-if="sessionsWithNote.length > 0">
          <h2 class="accordion-header" id="sessionHeading">
            <button class=" position-relative accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSessions" aria-expanded="false" aria-controls="collapseSessions">
              {{ wordings[currentLanguage].SECTION_SESSION }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute top-0 end translate-middle badge rounded-pill bg-primary">
                {{ sessionsWithNote.length }}
              </span>
            </button>
          </h2>
          <div id="collapseSessions" class="accordion-collapse collapse" aria-labelledby="sessionHeading"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <session-list :sessions="sessionsWithNote"></session-list>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="presentationsWithNote.length > 0">
          <h2 class="accordion-header" id="presentationHeading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapsePresentations" aria-expanded="false" aria-controls="collapsePresentations">
              {{ wordings[currentLanguage].SECTION_PRESENTATION }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute top-0 end translate-middle badge rounded-pill bg-primary">
                {{ presentationsWithNote.length }}
              </span>
            </button>
          </h2>
          <div id="collapsePresentations" class="accordion-collapse collapse" aria-labelledby="presentationHeading"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <presentation-list :presentations="presentationsWithNote" :with-date="true"></presentation-list>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="personsWithNote.length > 0">
          <h2 class="accordion-header" id="personHeading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapsePersons" aria-expanded="false" aria-controls="collapsePersons">
              {{ wordings[currentLanguage].SECTION_PERSON }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute top-0 end translate-middle badge rounded-pill bg-primary">
                {{ personsWithNote.length }}
              </span>
            </button>
          </h2>
          <div id="collapsePersons" class="accordion-collapse collapse" aria-labelledby="personHeading"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <person-list :persons="personsWithNote"></person-list>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="exhibitorsWithNote.length > 0">
          <h2 class="accordion-header" id="exhibitorHeading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseExhibitors" aria-expanded="false" aria-controls="collapseExhibitors">
              {{ wordings[currentLanguage].EXHIBITORS }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute top-0 end translate-middle badge rounded-pill bg-primary">
                {{ exhibitorsWithNote.length }}
              </span>
            </button>
          </h2>
          <div id="collapseExhibitors" class="accordion-collapse collapse" aria-labelledby="exhibitorHeading"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <exhibitor-list :exhibitors="exhibitorsWithNote"></exhibitor-list>
            </div>
          </div>
        </div>
      </div>

    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, IonSegment,IonSpinner, IonSegmentButton,isPlatform} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapState} from "vuex";
import SessionList from "@/components/SessionList";
import PersonList from "@/components/PersonList";
import PresentationList from "@/components/PresentationList";
import ExhibitorList from "@/components/ExhibitorList";



export default defineComponent({
  name: "Subpages",
  data() {
    return {
      favorites: [],
      notes: [],
      sessions: [],
      presentations: [],
      persons: [],
      exhibitors: [],
      sessionsWithNote: [],
      presentationsWithNote: [],
      personsWithNote: [],
      exhibitorsWithNote: [],
      activeSection: "favorites",
      loaded: false,
      shareData :''
    };
  },
  components: {
    IonContent,
    "session-list": SessionList,
    "person-list": PersonList,
    "presentation-list": PresentationList,
    "exhibitor-list": ExhibitorList,
    IonSegment,
    IonSegmentButton,
    IonSpinner
  },
  computed: {
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion']),

  },
  methods: {
    ...mapActions("personalDB", ["connectPersonalDB", 'getItem', 'getItems', 'prepareFavoriteList', 'prepareNoteList', 'prepareSessionShare', 'preparePresentationShare', 'preparePersonShare', 'prepareExhibitionShare']),
    changeTab(section) {
      this.activeTab = section;
    },
    async fetchSessions() {
      let sessions = (this.favorites.sessions).sort((a, b) => {
        return a.location_order - b.location_order;
      })
      this.sessions = sessions.sort((a, b) => {
        return new Date(a.start_time) - new Date(b.start_time);
      });
    },
    async fetchSessionsWithNotes() {
      let sessions = (this.notes.sessions).sort((a, b) => {
        return a.location_order - b.location_order;
      })
      this.sessionsWithNote = sessions.sort((a, b) => {
        return new Date(a.start_time) - new Date(b.start_time);
      });
    },


    exportViaMail(withFavorites, withNotes) {
      if (isPlatform('android') && typeof cordova != 'undefined') {

        let ref = cordova.InAppBrowser.open(this.shareData, '_system', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line
      }else{
        window.open(this.shareData, '_blank');
      }
    },

    async prepareExport(withFavorites, withNotes) {
      let sessionShareBlock = '';
      let presentationShareBlock = '';
      let personShareBlock = '';
      let abstractShareBlock = '';
      let exhibitionShareBlock = '';
      let favoriteExportBlock = '';

      let sessionNotesShareBlock = '';
      let presentationNotesShareBlock = '';
      let personNotesShareBlock = '';
      let exhibitionNotesShareBlock = '';
      let abstractNotesShareBlock = '';
      let noteExportBlock = '';

      if (withFavorites) {
        if (this.sessions) {
          for (let s = 0; s < this.sessions.length; s++) {
            let share = await this.prepareSessionShare(this.sessions[s]);
            sessionShareBlock = sessionShareBlock + share + '\n\n';
          }
        }

        if (this.presentations) {
          for (let p = 0; p < this.presentations.length; p++) {
            let share = await this.preparePresentationShare(this.presentations[p]);
            presentationShareBlock = presentationShareBlock + share + '\n\n';

          }
        }

        if (this.persons) {
          for (let per = 0; per < this.persons.length; per++) {
            let share = await this.preparePersonShare(this.persons[per]);
            personShareBlock = personShareBlock + share + '\n\n';

          }
        }

        if (this.exhibitors) {

          for (let ex = 0; ex < this.exhibitors.length; ex++) {
            let share = await this.prepareExhibitionShare(this.exhibitors[ex]);
            exhibitionShareBlock = exhibitionShareBlock + share + '\n\n';

          }
        }
      }
      if (withNotes) {
        if (this.sessionsWithNote) {

          for (let sessNotes = 0; sessNotes < this.sessionsWithNote.length; sessNotes++) {
            let share = await this.prepareSessionShare(this.sessionsWithNote[sessNotes]);
            sessionNotesShareBlock = sessionNotesShareBlock + share + '\n\n';
          }
        }

        if (this.presentationsWithNote) {
          for (let presNotes = 0; presNotes < this.presentationsWithNote.length; presNotes++) {
            let share = await this.preparePresentationShare(this.presentationsWithNote[presNotes]);
            presentationNotesShareBlock = presentationNotesShareBlock + share + '\n\n';
          }
        }

        if (this.personsWithNote) {
          for (let persNotes = 0; persNotes < this.personsWithNote.length; persNotes++) {

            let share = await this.preparePersonShare(this.personsWithNote[persNotes]);
            console.log('PERSON', share)
            personNotesShareBlock = personNotesShareBlock + share + '\n\n';
          }
        }

        if (this.exhibitorsWithNote) {
          for (let exNotes = 0; exNotes < this.exhibitorsWithNote.length; exNotes++) {
            let share = await this.prepareExhibitionShare(this.exhibitorsWithNote[exNotes]);
            exhibitionNotesShareBlock = exhibitionNotesShareBlock + share + '\n\n';
          }
        }
      }


      if (withFavorites) {
        favoriteExportBlock += sessionShareBlock ? 'Sessions' + ':\n\n' + sessionShareBlock : '';
        favoriteExportBlock += presentationShareBlock ? 'Presentations' + ':\n\n' + presentationShareBlock : '';
        favoriteExportBlock += personShareBlock ? 'Persons' + ':\n\n' + personShareBlock : '';
        favoriteExportBlock += abstractShareBlock ? 'Abstracts' + ':\n\n' + abstractShareBlock : '';
        favoriteExportBlock += exhibitionShareBlock ? 'Exhibitors' + ':\n\n' + exhibitionShareBlock : '';
      }

      if (withNotes) {
        noteExportBlock += sessionNotesShareBlock ? 'Sessions' + ':\n\n' + sessionNotesShareBlock : '';
        noteExportBlock += presentationNotesShareBlock ? 'Presentations' + ':\n\n' + presentationNotesShareBlock : '';
        noteExportBlock += personNotesShareBlock ? 'Persons' + ':\n\n' + personNotesShareBlock : '';
        noteExportBlock += abstractNotesShareBlock ? 'Abstracts' + ':\n\n' + abstractNotesShareBlock : '';
        noteExportBlock += exhibitionNotesShareBlock ? 'Exhibitors' + ':\n\n' + exhibitionNotesShareBlock : '';
      }

      let noteResultBlock = noteExportBlock ? 'My Notes' + ':\n\n' + noteExportBlock : noteExportBlock;
      let favoriteResultBlock = favoriteExportBlock ? 'My Favorites' + ':\n\n' + favoriteExportBlock : favoriteExportBlock;
      let result = '';
      if (withFavorites && !withNotes) {
        result = favoriteResultBlock;
      } else if (!withFavorites && withNotes) {
        result = noteResultBlock
      } else if (withFavorites && withNotes) {
        result = favoriteResultBlock + noteResultBlock
      }
      return result;

    },

  

  },

  async created() {
    this.loaded = false;
    await this.connectPersonalDB();
    let confFavorites = await this.getItem('favorites_' + this.currentConference);
    let confNotes = await this.getItem('notes_' + this.currentConference);
    console.log('CONFFAV', confFavorites)
    this.favorites = await this.prepareFavoriteList(confFavorites);
    this.notes = await this.prepareNoteList(confNotes);
    console.log(this.favorites)
    this.fetchSessions();
    this.presentations = this.favorites.presentations;
    this.persons = this.favorites.persons;
    this.exhibitors = this.favorites.exhibitors;

    this.fetchSessionsWithNotes();
    this.presentationsWithNote = this.notes.presentations;
    this.personsWithNote = this.notes.persons;
    this.exhibitorsWithNote = this.notes.exhibitors;
    this.loaded = true

    
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(route) {
        if (route.name == 'My Congress') {
          this.loaded = false;
          await this.connectPersonalDB();
          let confFavorites = await this.getItem('favorites_' + this.currentConference);
          let confNotes = await this.getItem('notes_' + this.currentConference);
          this.favorites = await this.prepareFavoriteList(confFavorites);
          this.notes = await this.prepareNoteList(confNotes);
  console.log("this.favorites")
          console.log(this.favorites)
          //this.sessions = this.favorites.sessions;
          this.fetchSessions();
          this.presentations = this.favorites.presentations;
          this.persons = this.favorites.persons;
          this.exhibitors = this.favorites.exhibitors;
          this.fetchSessionsWithNotes();
          this.presentationsWithNote = this.notes.presentations;
          this.personsWithNote = this.notes.persons;
          this.exhibitorsWithNote = this.notes.exhibitors;
          let result = await this.prepareExport(true, true);
          this.shareData = "mailto:?body=" + encodeURIComponent(result) + "&subject=" + this.fullName + ": Favorites " + encodeURIComponent('&') + " Notes";
          this.loaded = true
        }
      }
    }
  }
});
</script>
<style lang="scss" scoped>
a {
  text-decoration: none
}

.accordion-body {
  padding: 0 !important;
}

.mail-button {
  position:absolute;
  z-index: 10000;
  bottom: 0px;
  //width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid white;;
  z-index: 11;
  border-radius: 0px;
  font-size: 18px;
}

</style>
